*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: Inter;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: monospace, ui-monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

a {
  --tw-text-opacity: 1;
  color: rgb(19 20 20 / var(--tw-text-opacity));
}

@media (prefers-color-scheme: dark) {
  a {
    --tw-text-opacity: 1;
    color: rgb(254 255 255 / var(--tw-text-opacity));
  }
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-x-2 {
  left: .5rem;
  right: .5rem;
}

.-right-2 {
  right: -.5rem;
}

.-top-3 {
  top: -.75rem;
}

.bottom-1 {
  bottom: .25rem;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: .25rem;
}

.right-0 {
  right: 0;
}

.top-4 {
  top: 1rem;
}

.top-6 {
  top: 1.5rem;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-40 {
  z-index: 40;
}

.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-\[-4px\] {
  margin-bottom: -4px;
}

.ml-\[-11px\] {
  margin-left: -11px;
}

.ml-\[-450\] {
  margin-left: -450px;
}

.mr-0 {
  margin-right: 0;
}

.mr-\[-450\] {
  margin-right: -450px;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-\[-12px\] {
  margin-top: -12px;
}

.mt-\[-16px\] {
  margin-top: -16px;
}

.mt-\[-20px\] {
  margin-top: -20px;
}

.mt-\[-22px\] {
  margin-top: -22px;
}

.mt-\[-23px\] {
  margin-top: -23px;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.flow-root {
  display: flow-root;
}

.hidden {
  display: none;
}

.h-5 {
  height: 1.25rem;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[800px\] {
  height: 800px;
}

.h-\[80px\] {
  height: 80px;
}

.h-full {
  height: 100%;
}

.w-\[180px\] {
  width: 180px;
}

.w-\[220px\] {
  width: 220px;
}

.w-\[auto\], .w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.min-w-\[250px\] {
  min-width: 250px;
}

.min-w-min {
  min-width: min-content;
}

.max-w-\[180px\] {
  max-width: 180px;
}

.max-w-\[209px\] {
  max-width: 209px;
}

.max-w-\[542px\] {
  max-width: 542px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-prose {
  max-width: 65ch;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-initial {
  flex: 0 auto;
}

.flex-none {
  flex: none;
}

.basis-1\/2 {
  flex-basis: 50%;
}

.basis-1\/3 {
  flex-basis: 33.3333%;
}

.basis-2\/3 {
  flex-basis: 66.6667%;
}

.basis-2\/4 {
  flex-basis: 50%;
}

.basis-auto {
  flex-basis: auto;
}

.-translate-y-8 {
  --tw-translate-y: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-6 {
  --tw-translate-y: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[0\.85\] {
  --tw-scale-x: .85;
  --tw-scale-y: .85;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  place-content: center;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.self-end {
  align-self: flex-end;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-full {
  border-radius: 9999px;
}

.bg-\[\#0F0F0F\] {
  --tw-bg-opacity: 1;
  background-color: rgb(15 15 15 / var(--tw-bg-opacity));
}

.bg-\[\#3A3A3A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(58 58 58 / var(--tw-bg-opacity));
}

.bg-theme-1 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 255 255 / var(--tw-bg-opacity));
}

.bg-theme-2 {
  --tw-bg-opacity: 1;
  background-color: rgb(228 233 237 / var(--tw-bg-opacity));
}

.bg-theme-2\/95 {
  background-color: #e4e9edf2;
}

.bg-theme-5 {
  --tw-bg-opacity: 1;
  background-color: rgb(38 39 39 / var(--tw-bg-opacity));
}

.bg-theme-7\/90 {
  background-color: #131414e6;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.fill-airbo {
  fill: #3f4141;
}

.fill-athletesocial {
  fill: #121313;
}

.fill-current {
  fill: currentColor;
}

.fill-gammmma, .fill-netflix {
  fill: #8b8d8d;
}

.fill-transparent {
  fill: #0000;
}

.fill-vts {
  fill: #3e4141;
}

.p-4 {
  padding: 1rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.font-mdprimer-bold {
  font-family: MD Primer Bold, cursive;
}

.font-mdprimer-regular {
  font-family: MD Primer Regular, cursive;
}

.font-sans {
  font-family: Inter;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.text-theme-1 {
  --tw-text-opacity: 1;
  color: rgb(254 255 255 / var(--tw-text-opacity));
}

.text-theme-3 {
  --tw-text-opacity: 1;
  color: rgb(166 168 170 / var(--tw-text-opacity));
}

.text-theme-4 {
  --tw-text-opacity: 1;
  color: rgb(130 131 134 / var(--tw-text-opacity));
}

.text-theme-5 {
  --tw-text-opacity: 1;
  color: rgb(38 39 39 / var(--tw-text-opacity));
}

.text-theme-7 {
  --tw-text-opacity: 1;
  color: rgb(19 20 20 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-30 {
  opacity: .3;
}

.opacity-60 {
  opacity: .6;
}

.blur-md {
  --tw-blur: blur(12px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-sm {
  --tw-blur: blur(4px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-100 {
  transition-delay: .1s;
}

.delay-75 {
  transition-delay: 75ms;
}

.duration-300 {
  transition-duration: .3s;
}

@font-face {
  font-family: MD Primer Bold;
  src: url("MDPrimerTest-Bold.81408388.otf");
}

@font-face {
  font-family: MD Primer Regular;
  src: url("MDPrimerTest-Light.1c25f34c.otf");
}

.aLink {
  -webkit-text-stroke: 1px #828386;
}

[x-cloak] {
  display: none !important;
}

.container {
  max-width: -moz-fit-content;
  max-width: fit-content;
  overflow-x: auto;
}

.panel {
  font-weight: 500;
}

.img {
  object-fit: contain;
  height: 475px;
}

.child {
  height: auto;
  overflow: hidden;
}

.after\:content-\[\'↗\'\]:after {
  --tw-content: "↗";
  content: var(--tw-content);
}

.hover\:z-10:hover {
  z-index: 10;
}

.hover\:z-20:hover {
  z-index: 20;
}

.hover\:text-theme-4:hover {
  --tw-text-opacity: 1;
  color: rgb(130 131 134 / var(--tw-text-opacity));
}

.hover\:text-theme-7:hover {
  --tw-text-opacity: 1;
  color: rgb(19 20 20 / var(--tw-text-opacity));
}

.group:hover .group-hover\:fill-airbo-color {
  fill: #585c84;
}

.group:hover .group-hover\:fill-athletesocial-color {
  fill: #2b598e;
}

.group:hover .group-hover\:fill-gammmma-color {
  fill: #8e7952;
}

.group:hover .group-hover\:fill-netflix-color {
  fill: #697751;
}

.group:hover .group-hover\:fill-theme-1 {
  fill: #feffff;
}

.group:hover .group-hover\:fill-theme-5 {
  fill: #262727;
}

.group:hover .group-hover\:fill-vts-color {
  fill: #775151;
}

.group:hover .group-hover\:fill-white {
  fill: #fff;
}

.group:hover .group-hover\:text-inherit {
  color: inherit;
}

@media (prefers-color-scheme: dark) {
  .dark\:bg-theme-5 {
    --tw-bg-opacity: 1;
    background-color: rgb(38 39 39 / var(--tw-bg-opacity));
  }

  .dark\:bg-theme-6 {
    --tw-bg-opacity: 1;
    background-color: rgb(29 31 31 / var(--tw-bg-opacity));
  }

  .dark\:bg-theme-6\/95 {
    background-color: #1d1f1ff2;
  }

  .dark\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgb(134 239 172 / var(--tw-text-opacity));
  }

  .dark\:text-theme-1 {
    --tw-text-opacity: 1;
    color: rgb(254 255 255 / var(--tw-text-opacity));
  }

  .dark\:text-theme-3 {
    --tw-text-opacity: 1;
    color: rgb(166 168 170 / var(--tw-text-opacity));
  }

  .dark\:text-theme-4 {
    --tw-text-opacity: 1;
    color: rgb(130 131 134 / var(--tw-text-opacity));
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .hover\:dark\:text-theme-1:hover {
    --tw-text-opacity: 1;
    color: rgb(254 255 255 / var(--tw-text-opacity));
  }

  .hover\:dark\:text-theme-3:hover {
    --tw-text-opacity: 1;
    color: rgb(166 168 170 / var(--tw-text-opacity));
  }
}

@media (width >= 640px) {
  .sm\:inline {
    display: inline;
  }
}

@media (width >= 768px) {
  .md\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .md\:left-1\/4 {
    left: 25%;
  }

  .md\:right-0 {
    right: 0;
  }

  .md\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:ml-\[-32px\] {
    margin-left: -32px;
  }

  .md\:ml-\[-42px\] {
    margin-left: -42px;
  }

  .md\:ml-\[0\] {
    margin-left: 0;
  }

  .md\:mr-24 {
    margin-right: 6rem;
  }

  .md\:mr-\[-20\] {
    margin-right: -20px;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-20 {
    margin-top: 5rem;
  }

  .md\:mt-40 {
    margin-top: 10rem;
  }

  .md\:mt-\[-12px\] {
    margin-top: -12px;
  }

  .md\:mt-\[-34px\] {
    margin-top: -34px;
  }

  .md\:mt-\[-46px\] {
    margin-top: -46px;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:h-\[480px\] {
    height: 480px;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-28 {
    width: 7rem;
  }

  .md\:w-\[200px\] {
    width: 200px;
  }

  .md\:w-\[260px\] {
    width: 260px;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:min-w-\[300px\] {
    min-width: 300px;
  }

  .md\:min-w-\[540px\] {
    min-width: 540px;
  }

  .md\:max-w-\[542px\] {
    max-width: 542px;
  }

  .md\:basis-3\/5 {
    flex-basis: 60%;
  }

  .md\:transform-none {
    transform: none;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:overflow-auto {
    overflow: auto;
  }

  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md\:px-\[44px\] {
    padding-left: 44px;
    padding-right: 44px;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:pb-20 {
    padding-bottom: 5rem;
  }

  .md\:pr-20 {
    padding-right: 5rem;
  }

  .md\:pr-4 {
    padding-right: 1rem;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:pt-20 {
    padding-top: 5rem;
  }
}

@media (width >= 1024px) {
  .lg\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .lg\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (width >= 1280px) {
  .xl\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .xl\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .xl\:w-auto {
    width: auto;
  }
}

@media (width >= 1536px) {
  .\32 xl\:invisible {
    visibility: hidden;
  }

  .\32 xl\:relative {
    position: relative;
  }

  .\32 xl\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .\32 xl\:left-auto {
    left: auto;
  }

  .\32 xl\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .\32 xl\:ml-\[-200\] {
    margin-left: -200px;
  }

  .\32 xl\:ml-\[-50\] {
    margin-left: -50px;
  }

  .\32 xl\:-translate-y-0 {
    --tw-translate-y: -0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .\32 xl\:justify-end {
    justify-content: flex-end;
  }

  .\32 xl\:self-auto {
    align-self: auto;
  }

  .\32 xl\:text-center {
    text-align: center;
  }

  .\32 xl\:text-theme-1 {
    --tw-text-opacity: 1;
    color: rgb(254 255 255 / var(--tw-text-opacity));
  }
}

/*# sourceMappingURL=index.ad86f5a1.css.map */
