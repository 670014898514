@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "MD Primer Bold";
    src: url("../fonts/MDPrimerTest-Bold.otf");
}
@font-face {
    font-family: "MD Primer Regular";
    src: url("../fonts/MDPrimerTest-Light.otf")
}

@layer base {
    a {
        @apply text-theme-7 dark:text-theme-1;
    }
}


.aLink {
    -webkit-text-stroke: 1px #828386;
  }

[x-cloak] { display: none !important; }

.container {
  max-width: fit-content;
  overflow-x: auto;
}

.panel {
  font-weight: 500;
}

.img {
  object-fit:contain;
  height: 475px;
}

.child {
  overflow: hidden;
  height: auto;
}